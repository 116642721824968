import React from 'react'
import Layout from '../components/layout'
import myData from '../../data/bestarticles.json';
import { Link } from 'gatsby'
import parse from 'html-react-parser'

const BestArticlesPage = () => {

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    let prevcateg = "", prevsubcateg = "", mainhdr = "", subhdr = "";
    //console.log("myData=", myData)

    return (
        <Layout>
            <React.Fragment>
                <h1>Best Articles</h1>

                <div class="notify">

                    {/*
{
    "category":   "E-Commerce", 
    "subcategory": "",
    "link": "https://wh615253.ispot.cc/fbwh/how-to-create-a-woocommerce-website-quickly"}, 
*/}

                    {/*          <table>
                    <tbody> */}
                    {myData.queries.map((record, index) => {
                        const currentCategory = record.category
                        const currentSubCategory = record.subcategory

                        mainhdr = ""
                        subhdr = ""

                        if (currentCategory !== prevcateg) {
                            mainhdr = `<h2>${currentCategory}</h2>`
                            subhdr = !!currentSubCategory ? `<h3>${currentSubCategory}</h3>` : ""
                            prevcateg = currentCategory
                            prevsubcateg = currentSubCategory
                        }

                        if (currentCategory === prevcateg && prevsubcateg !== currentSubCategory) {
                            subhdr = !!currentSubCategory ? `<h3>${currentSubCategory}</h3>` : ""
                            prevsubcateg = currentSubCategory
                        }




                        const BlogLink = record.link
                            .replace(/https:\/\/wh615253.ispot.cc\/fbwh\//g, '/web-hosting-blog/index.php/')
                            .replace(/https:\/\/www.findbestwebhosting.com\/web-hosting-blog\//g, '/web-hosting-blog/index.php/')
                            .replace(/https:\/\/www.findbestwebhosting.com\//g, '/web-hosting-blog/index.php/')


                        const BlogLinkAnchorText = toTitleCase(record.link
                            .replace(/https:\/\/wh615253.ispot.cc\/fbwh\//g, '')
                            .replace(/https:\/\/www.findbestwebhosting.com\/web-hosting-blog\//g, '')
                            .replace(/-/g, ' '))

                        //console.log("BlogLink=", BlogLink)
                        //console.log("BlogLinkAnchorText=",BlogLinkAnchorText)

                        return (
                            <React.Fragment key={index}>
                                {parse(mainhdr)}
                                {parse(subhdr)}

                                <li key={index}>
                                    <Link
                                        to={BlogLink}
                                    >
                                        {BlogLinkAnchorText}
                                    </Link>
                                </li>

                            </React.Fragment>
                        )
                    })


                    }
                    {/*      </tbody>
                </table> */}
                </div>
            </React.Fragment>
        </Layout>
    )
}

export default BestArticlesPage
